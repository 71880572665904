/* eslint-disable radix */
import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
import { RestaurantState, CategoriesType } from '../../@types/restaurant';

// ----------------------------------------------------------------------

const initialState: RestaurantState = {
  isLoading: false,
  error: false,
  tags: {
    sortBy: null,
    data: []
  },
  variables: {
    sortBy: null,
    data: []
  },
  variable: {
    sortBy: null,
    data: []
  },
  products: {
    sortBy: null,
    data: [],
    filters: {
      priceAbove: null,
      priceBelow: null,
      available: null
    }
  },
  categories: {
    sortBy: null,
    data: []
  },
  ingredients: {
    sortBy: null,
    data: []
  },
  coupons: {
    sortBy: null,
    data: []
  },
  deals: {
    sortBy: null,
    data: []
  },
  promotions: {
    sortBy: null,
    data: []
  },
  reports: {
    orderType: [],
    summary: [],
    topCustomer: [],
    topProduct: [],
    sales: [],
    orderTime: []
  },
  tag: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  }
};

const slice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products.data = action.payload;
    },

    // GET TAGS
    getTagsSuccess(state, action) {
      state.isLoading = false;
      state.tags.data = action.payload;
    },

    // GET TAGS
    getTagSuccess(state, action) {
      state.isLoading = false;
      state.tags.data = action.payload;
    },

    // DELETE TAGS
    deleteTagSuccess(state, action) {
      state.isLoading = false;
      state.tags.data = action.payload;
    },
    // ADD TAGS
    addTagSuccess(state, action) {
      state.isLoading = false;
      state.tags.data.push(action.payload);
    },

    // UPDATE TAGS
    updateTagSuccess(state, action) {
      state.isLoading = false;
    },

    //  SORT & FILTER tags
    sortByTag(state, action) {
      state.tags.sortBy = action.payload;
    },

    filterTag(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // GET  VARIABLES
    getVariablesSuccess(state, action) {
      state.isLoading = false;
      state.variables.data = action.payload;
      state.variable.data = action.payload;
    },

    // ADD VARIABLE
    addVariableSuccess(state, action) {
      state.isLoading = false;
      state.variables.data = [...state.variables.data, action.payload];
    },

    // UPDATE VARIABLE
    updateVariableSuccess(state, action) {
      state.isLoading = false;
      state.variables.data[action.payload.index] = action.payload.data;
    },

    // GET COUPONS
    getCouponsSuccess(state, action) {
      state.isLoading = false;
      state.coupons.data = action.payload;
    },

    //  SORT & FILTER tags
    sortByVariable(state, action) {
      state.variables.sortBy = action.payload;
    },
    // GET CATEGORY
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories.data = action.payload;
    },
    //  SORT & FILTER CATEGORY
    sortByCategory(state, action) {
      state.categories.sortBy = action.payload;
    },
    // UPDATE CATEGORY
    updateCategorySuccess(state, action) {
      state.isLoading = false;
    },
    // GET INGREDIENT
    getIngredientsSuccess(state, action) {
      state.isLoading = false;
      state.ingredients.data = action.payload;
    },
    //  SORT & FILTER INGREDIENT
    sortByIngredient(state, action) {
      state.ingredients.sortBy = action.payload;
    },
    // UPDATE INGREDIENT
    updateIngredientSuccess(state, action) {
      state.isLoading = false;
      state.ingredients.data = action.payload;
    },
    // ADD INGREDIENT
    addIngredientsSuccess(state, action) {
      state.isLoading = false;
      state.ingredients.data.push(action.payload);
    },
    // GET DEALS
    getDealsSuccess(state, action) {
      state.isLoading = false;
      state.deals.data = action.payload;
    },
    //  SORT & FILTER deals
    sortByDeals(state, action) {
      state.deals.sortBy = action.payload;
    },
    //  SORT & FILTER product
    sortByProduct(state, action) {
      state.products.sortBy = action.payload;
    },
    filterProduct(state, action) {
      state.products.filters.priceBelow = action.payload.priceBelow;
      state.products.filters.priceAbove = action.payload.priceAbove;
      state.products.filters.available = action.payload.available;
    },
    //  SORT & FILTER PROMOTIONS
    sortByPromotion(state, action) {
      state.deals.sortBy = action.payload;
    },
    // GET PROMOTIONS
    getPromotionsSuccess(state, action) {
      state.isLoading = false;
      state.promotions.data = action.payload;
    },
    // GET Reports
    getReportsOrderTypesSuccess(state, action) {
      state.isLoading = false;
      state.reports.orderType = action.payload;
    },
    // GET Reports
    getReportsSummarySuccess(state, action) {
      state.isLoading = false;
      state.reports.summary = action.payload;
    },
    // GET Reports
    getReportsTopCustomersSuccess(state, action) {
      state.isLoading = false;
      state.reports.topCustomer = action.payload;
    },
    // GET Reports
    getReportsTopProductsSuccess(state, action) {
      state.isLoading = false;
      state.reports.topProduct = action.payload;
    },
    // GET Reports
    getReportsSalesSuccess(state, action) {
      state.isLoading = false;
      state.reports.sales = action.payload;
    },
    // GET Reports
    getOrderTimesSuccess(state, action) {
      state.isLoading = false;
      state.reports.orderTime = action.payload;
    }
  }
});

// Reducer

export default slice.reducer;

// Actions

export const {
  startLoading,
  hasError,
  getProductsSuccess,
  getTagSuccess,
  getTagsSuccess,
  deleteTagSuccess,
  sortByTag,
  filterTag,
  getVariablesSuccess,
  sortByVariable,
  getCategoriesSuccess,
  sortByCategory,
  updateCategorySuccess,
  getIngredientsSuccess,
  addIngredientsSuccess,
  sortByIngredient,
  updateIngredientSuccess,
  sortByDeals,
  sortByPromotion,
  sortByProduct,
  filterProduct,
  getDealsSuccess
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get(`/v1/products`);

      response.data.products.map((product: any) =>
        product.variables?.map((v: any) => {
          v._id = v.variable._id;
          v.name = v.variable.name;
          v.attributes.map((va: any) => {
            va.id = va.attribute._id;
            va.content = va.attribute.name;
          });
        })
      );
      response.data.products.map((product: any) =>
        product.extras?.map((v: any) => {
          v._id = v.variable._id;
          v.name = v.variable.name;
          v.attributes.map((va: any) => {
            va.id = va.attribute._id;
            va.content = va.attribute.name;
          });
        })
      );

      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addProducts(
  values: {
    sku: string | null;
    name: string;
    description: string;
    base_price: number;
    category: any;
    variables: any;
    extras: any;
    ingredients: any;
    tags: any;
    image: any;
    available: boolean;
  },
  isVar: boolean,
  isIngredient: boolean,
  isExtra: boolean
) {
  return async () => {
    const { dispatch } = store;
    if (isVar) {
      if (typeof values.variables[0] === 'object') {
        const varData = [
          {
            variable: values.variables[0]?._id,
            attributes: values.variables[0]?.attributes.map(
              (attr: { id: string; price: number }) => ({
                attribute: attr.id,
                price: attr.price
              })
            )
          }
        ];
        values.variables = varData;
      }
    } else {
      values.variables = [];
    }
    if (isExtra) {
      if (typeof values.extras[0] === 'object') {
        const varData = [
          {
            variable: values.extras[0]?._id,
            attributes: values.extras[0]?.attributes.map((attr: { id: string; price: number }) => ({
              attribute: attr.id,
              price: attr.price
            }))
          }
        ];
        values.extras = varData;
      }
    } else {
      values.extras = [];
    }
    if (isIngredient === true) {
      values.ingredients = values.ingredients.map((ing: any) => ({
        name: ing.name,
        parent: ing._id,
        items: ing.items.map((item: any) => ({
          item_parent: item.ingredient_item_id,
          include: item.include ?? false,
          name: item.name,
          price: item.price,
          variables: item.variables?.map((v: any) => ({
            price: v.price,
            variable_item: v.variable_item
          }))
        }))
      }));
    } else {
      values.ingredients = [];
    }
    dispatch(slice.actions.startLoading());

    try {
      let data: any = values;
      const formData = new FormData();
      formData.append('file', values.image);
      const imageUrl = await axios({
        method: 'post',
        url: '/v1/upload',
        data: formData,
        headers: {
          'Content-Type': `multipart/form-data`
        }
      });
      data = {
        sku: values.sku,
        name: values.name,
        description: values.description || ' ',
        base_price: values.base_price,
        category: values.category,
        variables: values.variables,
        extras: values.extras,
        ingredients: values.ingredients,
        tags: values.tags,
        image: imageUrl.data.image,
        available: values.available,
        visibility: 'visible'
      };
      await axios.post(`/v1/products`, data);
      await dispatch(getProducts());
    } catch (error: any) {
      alert('create error');
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateProducts(
  values: {
    sku: string | null;
    name: string;
    description: string;
    base_price: number;
    category: any;
    variables: any;
    extras: any;
    ingredients: any;
    tags: any;
    image: any;
    available: boolean;
    visibility: string;
  },
  id: string | undefined,
  isImageChanged: boolean,
  isVar: boolean,
  isIngredient: boolean,
  isExtra: boolean
) {
  return async () => {
    const { dispatch } = store;
    if (isVar) {
      if (typeof values.variables[0] === 'object') {
        const varData = [
          {
            variable: values.variables[0]?._id,
            attributes: values.variables[0]?.attributes.map(
              (attr: { id: string; price: number }) => ({
                attribute: attr.id,
                price: attr.price
              })
            )
          }
        ];
        values.variables = varData;
      }
    } else {
      values.variables = [];
    }
    if (isExtra) {
      if (typeof values.extras[0] === 'object') {
        const varData = [
          {
            variable: values.extras[0]?._id,
            attributes: values.extras[0]?.attributes.map((attr: { id: string; price: number }) => ({
              attribute: attr.id,
              price: attr.price
            }))
          }
        ];
        values.extras = varData;
      }
    } else {
      values.extras = [];
    }

    if (isIngredient === true) {
      const data = values.ingredients
        .filter((ing: any) => ing.parent === undefined)
        .map((ing: any) => ({
          name: ing.name,
          parent: ing._id,
          items: ing.items.map((item: any) => ({
            item_parent: item.ingredient_item_id,
            include: item.include ?? false,
            name: item.name,
            variables: item.variables?.map((v: any) => ({
              price: v.price,
              variable_item: v.variable_item
            }))
          }))
        }));
      const newData = values.ingredients
        .filter((ing: any) => ing.parent !== undefined)
        .map((ing: any) => ({
          name: ing.name,
          _id: ing._id,
          items: ing.items.map((item: any) => ({
            include: item.include ?? false,
            name: item.name,
            variables: item.variables?.map((v: any) => ({
              price: v.price,
              variable_item: v.variable_item
            }))
          }))
        }));
      values.ingredients = data.concat(newData);
    } else {
      values.ingredients = [];
    }
    dispatch(slice.actions.startLoading());

    try {
      let data: any = values;
      if (isImageChanged) {
        const formData = new FormData();
        formData.append('file', values.image);
        const imageUrl = await axios({
          method: 'post',
          url: '/v1/upload',
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data`
          }
        });
        data = {
          sku: values.sku,
          name: values.name,
          description: values.description || ' ',
          base_price: values.base_price,
          category: values.category,
          variables: values.variables,
          extras: values.extras,
          ingredients: values.ingredients,
          tags: values.tags,
          image: imageUrl.data.image,
          available: values.available,
          visibility: values.visibility
        };
      }
      await axios.put(`/v1/products/${id}/update`, data);

      await dispatch(getProducts());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteProducts(ids: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        ids.map(async (id) => {
          await axios.delete(`/v1/products/${id}`);
        })
      );

      dispatch(getProducts());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVisibilityProduct(id: string, data: boolean) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put(`/v1/products/${id}/update`, { available: data });
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTags() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get(`/v1/tags`);

      dispatch(slice.actions.getTagsSuccess(response.data.tag));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTag(name: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/api/tags/tag', {
        params: { name }
      });
      dispatch(slice.actions.getTagSuccess(response.data.tags));
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTags(ids: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        ids.map(async (id) => {
          await axios.delete(`/v1/tags/${id}`);
        })
      );

      dispatch(getTags());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateTag(
  values: {
    name: string;
    image: any | null;
    visibility: string | null;
  },
  _id: string | undefined,
  productList: any,
  isImageChanged: boolean
) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      let data: any = values;
      if (isImageChanged) {
        const formData = new FormData();
        formData.append('file', values.image);
        const imageUrl = await axios({
          method: 'post',
          url: '/v1/upload',
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data`
          }
        });
        data = {
          name: values.name,
          image: imageUrl.data.image,
          visibility: values.visibility
        };
      }
      const response: any = await axios.put(`/v1/tags/update/${_id}`, data);

      const tagId = response.data.tag._id;
      productList.map((product: any) => {
        const productId = product._id;
        axios.post(`/v1/products/update/${productId}`, { tags: [tagId] });
        dispatch(getTags());
      });

      await dispatch(getTags());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVisibilityTag(id: string, data: boolean) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put(`/v1/tags/update/${id}`, { available: data });
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addTag(values: { name: string; image: any }, productList: any) {
  return async () => {
    const { dispatch } = store;
    const body = new FormData();
    body.append('buffer', URL.createObjectURL(values.image));
    body.append('originalname', values.image.name);

    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('file', values.image);
      const imageUrl = await axios({
        method: 'post',
        url: '/v1/upload',
        data: formData,
        headers: {
          'Content-Type': `multipart/form-data`
        }
      });
      const data = {
        name: values.name,
        image: imageUrl.data.image,
        products: productList
      };
      await axios.post(`/v1/tags`, data);

      await dispatch(getTags());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getVariables() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.stopLoading());
    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get(`/v1/variables`);
      const processedData = response.data.variable.map((item: any) => ({
        ...item,
        attributes: item.attributes.map((attribute: any) => ({
          id: attribute._id,
          content: attribute.name
        }))
      }));

      dispatch(slice.actions.getVariablesSuccess(processedData));
    } catch (error: any) {
      console.log('error get', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteVariable(ids: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        ids.map(async (id) => {
          await axios.delete(`/v1/variables/${id}`);
        })
      );

      dispatch(getVariables());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addVariable(values: {
  name: string;
  attributes: { id: string; content: string }[] | null;
}) {
  return async () => {
    const { dispatch } = store;

    const newValues: any = { ...values };

    newValues.attributes = values.attributes?.map((attr) => ({ name: attr.content }));

    dispatch(slice.actions.startLoading());

    try {
      await axios.post('/v1/variables', newValues);

      await dispatch(getVariables());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function updateVariable(
  values: {
    name: string;
    attributes: any[] | null | undefined;
  },
  id: string | undefined
) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    values.attributes = values.attributes?.map((attr: any) => ({ ...attr, name: attr.content }));

    try {
      await axios.put(`/v1/variables/update/${id}`, values);

      await dispatch(getVariables());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVisibilityVariable(id: string, data: boolean) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put(`/v1/variables/update/${id}`, { available: data });

      await dispatch(getVariables());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get(`/v1/categories`);

      dispatch(slice.actions.getCategoriesSuccess(response.data.category));
    } catch (error: any) {
      console.log('error', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(ids: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        ids.map(async (id) => {
          await axios.delete(`/v1/categories/${id}`);
        })
      );

      dispatch(getCategories());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addCategory(values: { name: string; image: any | null }, productList: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const formData = new FormData();
      formData.append('file', values.image);
      const imageUrl = await axios({
        method: 'post',
        url: '/v1/upload',
        data: formData,
        headers: {
          'Content-Type': `multipart/form-data`
        }
      });
      const data = {
        name: values.name,
        image: imageUrl.data.image
      };
      const response: { data: { category: CategoriesType } } = await axios.post(
        `/v1/categories`,
        data
      );
      const categoryId = response.data.category._id;
      productList.map((product: any) => {
        const productId = product._id;
        const data = { productId, categoryId };
        axios.post(`/v1/categories/add-to-product`, data);
      });

      await dispatch(getCategories());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateCategory(
  values: {
    name: string;
    image: any;
    visibility: string | null;
  },
  _id: string | undefined,
  productList: any,
  isImageChanged: boolean
) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      let data: any = values;
      if (isImageChanged) {
        const formData = new FormData();
        formData.append('file', values.image);
        const imageUrl = await axios({
          method: 'post',
          url: '/v1/upload',
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data`
          }
        });
        data = {
          name: values.name,
          image: imageUrl.data.image
        };
      }
      const response: any = await axios.put(`/v1/categories/update/${_id}`, data);

      const categoryId = response.data.category._id;
      productList.map((product: any) => {
        const productId = product._id;
        const data = { productId, categoryId };
        axios.post(`/v1/categories/add-to-product`, data);
      });

      await dispatch(getCategories());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVisibilityCategory(id: string, data: boolean) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put(`/v1/categories/update/${id}`, { available: data });
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIngredients() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/v1/ingredients');

      dispatch(slice.actions.getIngredientsSuccess(response.data.ingredient));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getIngredientsByVariable(id: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get(`/v1/ingredients?variable=${id}`);

      dispatch(slice.actions.getIngredientsSuccess(response.data.ingredient));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteIngredient(id: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        id.map(async (i: string) => {
          await axios.delete(`/v1/ingredients/${i}`);
        })
      );

      dispatch(getIngredients());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function addIngredient(values: {
  name: string;
  items: { id: string; name: string; price: number[]; variables: any[] | null }[] | null;
  variable: string;
  ingredient_pricing: boolean;
  varIds: string[];
}) {
  return async () => {
    const { dispatch } = store;

    const newItems: any = values.items?.map((attr: any) => ({
      name: attr.name,
      variables:
        typeof attr.price !== 'string'
          ? attr.variables.map((vars: any, index: number) => ({
              variable_item: values.varIds[index],
              price: vars.price
            }))
          : attr.variables
    }));

    const newVal: any = {
      ...values,
      ingredient_pricing: values.ingredient_pricing,
      items: newItems
    };

    dispatch(slice.actions.startLoading());

    try {
      await axios.post('/v1/ingredients', newVal);

      await dispatch(getIngredients());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateIngredient(
  values: {
    name: string;
    items: { id: string; name: string; price: number[]; variables: any[] | null }[] | null;
    ingredient_pricing: boolean;
    varIds: string[];
  },
  id: string | undefined
) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const newItems: any = values.items?.map((attr: any) => ({
        name: attr.name,
        price: parseInt(attr.price) || 0,
        item_price: attr.item_price,
        variables:
          typeof attr.price !== 'string'
            ? attr.variables.map((vars: any, index: number) => ({
                variable_item: values.varIds[index],
                price: vars.price
              }))
            : attr.variables
      }));
      const newVal: any = {
        ...values,
        ingredient_pricing: values.ingredient_pricing,
        items: newItems
      };
      await axios.put(`/v1/ingredients/${id}/update`, newVal);

      await dispatch(getIngredients());
    } catch (error: any) {
      console.log('error update', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVisibilityIngredient(id: string, data: boolean) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put(`/v1/ingredients/update/${id}`, { available: data });

      await dispatch(getIngredients());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`

export function getCoupons() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/api/coupons');

      dispatch(slice.actions.getCouponsSuccess(response.data.restaurantCoupons));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteCoupons(id: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.delete('/api/coupons/', {
        params: { id }
      });

      dispatch(slice.actions.getCouponsSuccess(response.data.coupons));
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDeals() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/v1/deals');

      dispatch(slice.actions.getDealsSuccess(response.data.deals));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addDeal(values: {
  available: boolean;
  name: string;
  description: string;
  deals_type: string;
  deals_availability: string;
  usage_limit: any;
  user_limit: any;
  start_date: any;
  end_date: any;
  base_price: any;
  image: any;
  menus: any;
}) {
  return async () => {
    const { dispatch } = store;
    const body = new FormData();
    body.append('buffer', URL.createObjectURL(values.image));
    body.append('originalname', values.image.name);

    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('file', values.image);
      const imageUrl = await axios({
        method: 'post',
        url: '/v1/upload',
        data: formData,
        headers: {
          'Content-Type': `multipart/form-data`
        }
      });
      const data = {
        available: values.available,
        name: values.name,
        description: values.description,
        deals_type: values.deals_type,
        deals_availability: values.deals_availability,
        base_price: values.base_price,
        usage_limit: values.usage_limit,
        user_limit: values.user_limit,
        start_date: values.start_date,
        end_date: values.end_date,
        image: imageUrl.data.image,
        menus: values.menus
      };
      await axios.post(`/v1/deals`, data);

      await dispatch(getDeals());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDeal(
  values: {
    available: boolean;
    name: string;
    description: string;
    deals_type: string;
    deals_availability: string;
    usage_limit: any;
    user_limit: any;
    start_date: any;
    end_date: any;
    base_price: any;
    image: any;
    menus: any;
  },
  isImageChanged: boolean,
  id: string | undefined
) {
  return async () => {
    const { dispatch } = store;
    // const body = new FormData();
    // body.append('buffer', URL.createObjectURL(values.image));
    // body.append('originalname', values.image.name);

    dispatch(slice.actions.startLoading());
    try {
      const data: any = {
        available: values.available,
        name: values.name,
        description: values.description,
        deals_type: values.deals_type,
        deals_availability: values.deals_availability,
        base_price: values.base_price,
        usage_limit: values.usage_limit,
        user_limit: values.user_limit,
        start_date: values.start_date,
        image: values.image,
        end_date: values.end_date,
        menus: values.menus
      };
      if (isImageChanged) {
        const formData = new FormData();
        formData.append('file', values.image);
        const imageUrl = await axios({
          method: 'post',
          url: '/v1/upload',
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data`
          }
        });
        data.image = imageUrl.data.image;
      }
      await axios.put(`/v1/deals/update/${id}`, data);

      await dispatch(getDeals());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVisibilityDeal(id: string, data: boolean) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put(`/v1/deals/update/${id}`, { available: data });
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDeals(id: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        id.map(async (i: string) => {
          await axios.delete(`/v1/deals/${i}`);
        })
      );

      dispatch(getDeals());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getPromotions() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/v1/promotions');

      dispatch(slice.actions.getPromotionsSuccess(response.data.promotions));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePromotionById(promotionId: string[]) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await Promise.all(
        promotionId.map(async (id) => {
          await axios.delete(`/v1/promotions/${id}`);
        })
      );
      dispatch(getPromotions());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function addPromotions(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.post('/v1/promotions', values);

      await getPromotions();
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editVisibilityPromotion(id: string, data: boolean) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put(`/v1/promotions/${id}/update`, { available: data });
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getReports(period: string, setReportsLoading: any) {
  let interval: string;
  switch (period) {
    case 'This Month':
      interval = '1m';
      break;
    case 'This Week':
      interval = '1w';
      break;
    case 'This Year':
      interval = '1y';
      break;
    default:
      interval = '1m';
      break;
  }

  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    setReportsLoading(true);

    try {
      const response: any = await axios.get(`/v1/reports/summary?interval=${interval}`);

      dispatch(slice.actions.getReportsSummarySuccess(response.data.result.reports));
      setReportsLoading(false);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getOrderTypes(period: string, setLoading: any) {
  let interval: string;
  switch (period) {
    case 'This Month':
      interval = '1m';
      break;
    case 'This Week':
      interval = '1w';
      break;
    case 'This Year':
      interval = '1y';
      break;
    default:
      interval = '1y';
      break;
  }

  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    setLoading(true);

    try {
      const response: any = await axios.get(`/v1/reports/order/order-type?interval=${interval}`);

      dispatch(slice.actions.getReportsOrderTypesSuccess(response.data.result.reports));
      setLoading(false);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getTopCustomer(period: string, setLoading: any) {
  let interval: string;
  switch (period) {
    case 'This Month':
      interval = '1m';
      break;
    case 'This Week':
      interval = '1w';
      break;
    case 'This Year':
      interval = '1y';
      break;
    default:
      interval = '1y';
      break;
  }

  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    setLoading(true);

    try {
      const response: any = await axios.get(
        `/v1/reports/customers/top-customers?limit=5&interval=${interval}`
      );

      dispatch(slice.actions.getReportsTopCustomersSuccess(response.data.result));
      setLoading(false);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getTopProduct(period: string, setLoading: any) {
  let interval: string;
  switch (period) {
    case 'This Month':
      interval = '1m';
      break;
    case 'This Week':
      interval = '1w';
      break;
    case 'This Year':
      interval = '1y';
      break;
    default:
      interval = '1y';
      break;
  }

  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    setLoading(true);

    try {
      const response: any = await axios.get(
        `/v1/reports/products/top-products?limit=5&interval=${interval}`
      );

      dispatch(slice.actions.getReportsTopProductsSuccess(response.data.result.reports));
      setLoading(false);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getSalesReports(period: string, setSalesChartLoading: any) {
  let interval: string;
  switch (period) {
    case 'This Month':
      interval = '1m';
      break;
    case 'This Week':
      interval = '1w';
      break;
    case 'This Year':
      interval = '1y';
      break;
    default:
      interval = '1y';
      break;
  }

  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    setSalesChartLoading(true);

    try {
      const response: any = await axios.get(`/v1/reports/sales?interval=${interval}`);

      dispatch(slice.actions.getReportsSalesSuccess(response.data.result.reports));
      setSalesChartLoading(false);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getOrderTime(period: string, setLoading: any) {
  let interval: string;
  switch (period) {
    case 'This Month':
      interval = '1m';
      break;
    case 'This Week':
      interval = '1w';
      break;
    case 'This Year':
      interval = '1y';
      break;
    default:
      interval = '1y';
      break;
  }

  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    setLoading(true);

    try {
      const response: any = await axios.get(`/v1/reports/order/order-time?interval=${interval}`);

      dispatch(slice.actions.getOrderTimesSuccess(response.data.result.reports));
      setLoading(false);
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
