import { createSlice } from '@reduxjs/toolkit';

import { Notification, NotificationState } from '../../@types/notifications';

const initialState: NotificationState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(
      state,
      action: {
        payload: Notification;
      }
    ) {
      console.log('New notification triggered');
      state.notifications.push(action.payload);
    },

    clearNotifications(state) {
      state.notifications = [];
    },

    removeNotification(state, action) {
      const index = state.notifications.findIndex(
        (notification) => notification.id === action.payload
      );
      state.notifications.splice(index, 1);
    },

    markAllAsRead(state) {
      state.notifications.forEach((notification) => (notification.isUnRead = false));
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { addNotification, clearNotifications, removeNotification, markAllAsRead } =
  slice.actions;
