import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Default Routes
    {
      path: '*',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/app" replace /> },
        {
          path: 'restaurant',
          children: [
            { path: '', element: <Navigate to="/restaurant/product" replace /> },
            { path: 'product', element: <RestaurantProduct /> },
            { path: 'product/new', element: <ProductCreate /> },
            { path: 'product/:id/edit', element: <ProductCreate /> },
            { path: 'category', element: <RestaurantCategory /> },
            { path: 'category/new', element: <CategoryCreate /> },
            { path: 'category/:id/edit', element: <CategoryCreate /> },
            { path: 'tag', element: <RestaurantTags /> },
            { path: 'ingredients', element: <RestaurantIngredients /> },
            { path: 'ingredients/new', element: <IngredientsCreate /> },
            { path: 'ingredients/:id/edit', element: <IngredientsCreate /> },
            { path: 'variable', element: <RestaurantVariable /> },
            { path: 'tag/new', element: <TagCreate /> },
            { path: 'tag/:id/edit', element: <TagCreate /> },
            { path: 'variable/new', element: <VarCreate /> },
            { path: 'variable/:id/edit', element: <VarCreate /> },
            { path: 'coupons', element: <RestaurantCoupons /> },
            { path: 'coupons/new', element: <CouponsCreate /> },
            { path: 'deals', element: <RestaurantDeals /> },
            { path: 'deals/new', element: <DealsCreate /> },
            { path: 'deals/:id/edit', element: <DealsCreate /> },
            { path: 'promotions', element: <RestaurantPromotions /> },
            { path: 'promotion/new', element: <PromotionCreate /> }
          ]
        },
        {
          path: 'live-orders',
          children: [
            {
              path: '',
              element: <Navigate to="/live-orders/all" replace />
            },
            {
              path: 'all',
              element: (
                <RoleBasedGuard casl_id="order">
                  <AllOrders />
                </RoleBasedGuard>
              )
            },
            { path: 'draft', element: <DraftOrders /> },
            { path: 'cart', element: <Cart /> },
            { path: 'new', element: <OrderCreate /> },
            { path: ':orderId', element: <OrderDetails /> },
            { path: 'view', element: <ViewOnScreen /> }
          ]
        },
        {
          path: 'product-sales',
          children: [
            { path: '', element: <Navigate to="/product-sales/daily" replace /> },
            { path: 'daily', element: <ProductSalesDaily /> },
            { path: 'monthly', element: <ProductSalesMonthly /> },
            { path: 'by-product', element: <ProductSalesByProduct /> },
            { path: 'by-customer', element: <ProductSalesByCustomer /> }
          ]
        },
        {
          path: 'customers',
          children: [
            { path: '', element: <Navigate to="/customers/list" replace /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard casl_id="customers">
                  <Customers />
                </RoleBasedGuard>
              )
            },
            { path: 'new', element: <CustomersCreate /> },
            { path: ':customerId', element: <CustomersDetails /> }
          ]
        },
        {
          path: 'payment',
          children: [
            { path: '', element: <Navigate to="/payment/list" replace /> },
            { path: 'list', element: <Payments /> }
          ]
        },
        {
          path: 'settings',
          children: [
            { path: '', element: <Navigate to="/settings/general" replace /> },
            { path: 'general/newUser', element: <UserCreate /> },
            { path: 'general/newRole', element: <RoleCreate /> },
            { path: 'general/user/:userId/edit', element: <UserCreate /> },
            { path: 'general/role/:roleId/edit', element: <RoleCreate /> },
            {
              path: 'general',
              element: (
                <RoleBasedGuard casl_id="manage_settings">
                  <Settings />
                </RoleBasedGuard>
              )
            }
          ]
        },
        { path: 'app', element: <Dashboard /> }
      ]
    },

    // Main Routes
    {
      path: 'main',
      element: <MainLayout />
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));

// Restaurant
const RestaurantProduct = Loadable(lazy(() => import('../pages/restaurant/Product')));
const ProductCreate = Loadable(lazy(() => import('../pages/restaurant/newProduct')));
const RestaurantCategory = Loadable(lazy(() => import('../pages/restaurant/Category')));
const CategoryCreate = Loadable(lazy(() => import('../pages/restaurant/newCategory')));
const RestaurantTags = Loadable(lazy(() => import('../pages/restaurant/Tags')));
const RestaurantIngredients = Loadable(lazy(() => import('../pages/restaurant/Ingredients')));
const RestaurantVariable = Loadable(lazy(() => import('../pages/restaurant/Variables')));
const RestaurantCoupons = Loadable(lazy(() => import('../pages/restaurant/Coupons')));
const CouponsCreate = Loadable(lazy(() => import('../pages/restaurant/newCoupon')));
const IngredientsCreate = Loadable(lazy(() => import('../pages/restaurant/newIngredient')));
const TagCreate = Loadable(lazy(() => import('../pages/restaurant/newTag')));
const VarCreate = Loadable(lazy(() => import('../pages/restaurant/newVar')));
const RestaurantDeals = Loadable(lazy(() => import('../pages/restaurant/Deals')));
const RestaurantPromotions = Loadable(lazy(() => import('../pages/restaurant/Promotions')));
const PromotionCreate = Loadable(lazy(() => import('../pages/restaurant/newPromotion')));
const DealsCreate = Loadable(lazy(() => import('../pages/restaurant/newDeal')));

// Live Orders
const AllOrders = Loadable(lazy(() => import('../pages/live-orders/AllOrders')));
const DraftOrders = Loadable(lazy(() => import('../pages/live-orders/DraftOrders')));
const Cart = Loadable(lazy(() => import('../pages/live-orders/Cart')));
const OrderCreate = Loadable(lazy(() => import('../pages/live-orders/OrderCreate')));
const OrderDetails = Loadable(lazy(() => import('../pages/live-orders/OrderDetails')));
const ViewOnScreen = Loadable(lazy(() => import('../pages/live-orders/ViewOnScreen')));

// Product Sales
const ProductSalesDaily = Loadable(lazy(() => import('../pages/product-sales/Daily')));
const ProductSalesMonthly = Loadable(lazy(() => import('../pages/product-sales/Monthly')));
const ProductSalesByProduct = Loadable(lazy(() => import('../pages/product-sales/ByProduct')));
const ProductSalesByCustomer = Loadable(lazy(() => import('../pages/product-sales/ByCustomer')));

// Customer
const Customers = Loadable(lazy(() => import('../pages/customers/Customers')));
const CustomersCreate = Loadable(lazy(() => import('../pages/customers/CustomersCreate')));
const CustomersDetails = Loadable(lazy(() => import('../pages/customers/CustomersDetails')));

// Payment
const Payments = Loadable(lazy(() => import('../pages/payment/Payment')));

// Settings

const Settings = Loadable(lazy(() => import('../pages/settings/Settings')));
const UserCreate = Loadable(lazy(() => import('../pages/settings/NewUser')));
const RoleCreate = Loadable(lazy(() => import('../pages/settings/NewRole')));

// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
