import { createContext, ReactNode, useEffect, useReducer, useContext } from 'react';
import { AbilityContext, updateAbility } from './CASLContext';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
  GoogleOauth2 = 'GOOGLE_OAUTH2',
  FacebookOauth2 = 'FACEBOOK_OAUTH2'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
  [Types.GoogleOauth2]: {
    user: AuthUser;
  };
  [Types.FacebookOauth2]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    case 'GOOGLE_OAUTH2':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    case 'FACEBOOK_OAUTH2':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const ability = useContext(AbilityContext);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`/v1/admin`);
          const user = response.data.admin;

          updateAbility(ability, user.role.permissions);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axios.post(`/v1/admin/auth/login`, {
      email,
      password
    });

    const user = response.data.admin;
    const accessToken = response.headers['x-token'].toString();

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });

    updateAbility(ability, user.role.permissions);
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post(`/v1/admin`, {
      email,
      password,
      firstName,
      lastName
    });
    console.log(response.data);
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const loginWithGoogle = async (tokenId: string) => {
    const response = await axios.post(`/v1/admin/auth/google`, {
      tokenId
    });

    console.log(response.data);
    const { admin } = response.data;
    const accessToken = response.headers['x-token'];

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.GoogleOauth2,
      payload: {
        user: admin
      }
    });
  };

  const loginWithFaceBook = async (tokenId: string, userId: string) => {
    const response = await axios.post(`/v1/admin/auth/facebook`, {
      tokenId,
      userId
    });

    console.log(response.data);
    const { admin } = response.data;
    const accessToken = response.headers['x-token'];

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.GoogleOauth2,
      payload: {
        user: admin
      }
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
