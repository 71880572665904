// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
const ROOTS_LANDING = '/main';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/forgot-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: path(ROOTS_LANDING, '/coming-soon'),
  maintenance: path(ROOTS_LANDING, '/maintenance'),
  pricing: path(ROOTS_LANDING, '/pricing'),
  payment: path(ROOTS_LANDING, '/payment'),
  about: path(ROOTS_LANDING, '/about-us'),
  contact: path(ROOTS_LANDING, '/contact-us'),
  faqs: path(ROOTS_LANDING, '/faqs'),
  page404: '/404',
  page500: '/500',
  components: path(ROOTS_LANDING, '/components')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/app'),
    customers: path(ROOTS_DASHBOARD, '/customers'),
    integration: path(ROOTS_DASHBOARD, '/integration'),
    payment: path(ROOTS_DASHBOARD, '/payment'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
    app: path(ROOTS_DASHBOARD, '/old-dash')
  },
  liveorders: {
    root: path(ROOTS_DASHBOARD, '/live-orders'),
    allOrders: path(ROOTS_DASHBOARD, '/live-orders/all'),
    draftOrders: path(ROOTS_DASHBOARD, '/live-orders/draft'),
    cart: path(ROOTS_DASHBOARD, '/live-orders/cart'),
    newOrder: path(ROOTS_DASHBOARD, '/live-orders/new'),
    details: path(ROOTS_DASHBOARD, '/live-orders/:orderId'),
    viewOnScreen: path(ROOTS_DASHBOARD, '/live-orders/view')
  },
  restaurant: {
    root: path(ROOTS_DASHBOARD, '/restaurant'),
    product: path(ROOTS_DASHBOARD, '/restaurant/product'),
    newProduct: path(ROOTS_DASHBOARD, '/restaurant/product/new'),
    category: path(ROOTS_DASHBOARD, '/restaurant/category'),
    newCategory: path(ROOTS_DASHBOARD, '/restaurant/category/new'),
    tag: path(ROOTS_DASHBOARD, '/restaurant/tag'),
    ingredients: path(ROOTS_DASHBOARD, '/restaurant/ingredients'),
    newIngredient: path(ROOTS_DASHBOARD, '/restaurant/ingredients/new'),
    variable: path(ROOTS_DASHBOARD, '/restaurant/variable'),
    giftcards: path(ROOTS_DASHBOARD, '/restaurant/giftcards'),
    coupons: path(ROOTS_DASHBOARD, '/restaurant/coupons'),
    tags: path(ROOTS_DASHBOARD, '/restaurant/tag'),
    newTag: path(ROOTS_DASHBOARD, '/restaurant/tag/new'),
    newVariable: path(ROOTS_DASHBOARD, '/restaurant/variable/new'),
    newCoupon: path(ROOTS_DASHBOARD, '/restaurant/coupons/new'),
    deals: path(ROOTS_DASHBOARD, '/restaurant/deals'),
    newDeal: path(ROOTS_DASHBOARD, '/restaurant/deals/new'),
    promotions: path(ROOTS_DASHBOARD, '/restaurant/promotions'),
    newPromotion: path(ROOTS_DASHBOARD, '/restaurant/promotion/new')
  },
  customers: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    new: path(ROOTS_DASHBOARD, '/customers/new'),
    details: path(ROOTS_DASHBOARD, '/customers/:customerId')
  },
  product_sales: {
    root: path(ROOTS_DASHBOARD, '/product-sales'),
    daily: path(ROOTS_DASHBOARD, '/product-sales/daily'),
    monthly: path(ROOTS_DASHBOARD, '/product-sales/monthly'),
    by_product: path(ROOTS_DASHBOARD, '/product_sales/by-product'),
    by_customer: path(ROOTS_DASHBOARD, '/product_sales/by-customer')
  },
  payment: {
    root: path(ROOTS_DASHBOARD, '/payment')
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    newUser: path(ROOTS_DASHBOARD, '/settings/general/newUser'),
    newRole: path(ROOTS_DASHBOARD, '/settings/general/newRole')
  }
};

// export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

export const PATH_DOCS = '';
