import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
//
import { SettingState, SettingType } from '../../@types/settings';

// ----------------------------------------------------------------------

const initialState: SettingState = {
  isLoading: false,
  error: false,
  setting: {},
  members: [],
  roles: [],
  subscription: []
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SETTING
    getSettingSuccess(state, action) {
      state.isLoading = false;
      state.setting = action.payload;
    },

    // GET MEMBERS
    getMemberSuccess(state, action) {
      state.isLoading = false;
      state.members = action.payload;
    },

    // GET MEMBERS
    getRoleSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },

    // toggle open
    toggleOpenSetting(state) {
      state.setting.open = !state.setting.open;
    },
    // GET Subscription
    getAvailablePlansSuccess(state, action) {
      state.isLoading = false;
      state.subscription = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getSettingSuccess } = slice.actions;

// ----------------------------------------------------------------------`
export function updateAddressSetting(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const data = {
        address: {
          address_detail: values.address_detail,
          country: values.country,
          region: values.region,
          city: values.city,
          postcode: values.postcode
        }
      };
      await axios.put('/v1/restaurants', data);

      await dispatch(getRestaurantSettings());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateInformationSetting(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const data = {
        logo: values.logo,
        business_name: values.business_name,
        phone: values.phone,
        email: values.email,
        currency: values.currency,
        language: values.language,
        timezone: values.timezone
      };
      await axios.put('/v1/restaurants', data);

      await dispatch(getRestaurantSettings());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateNotificationSetting(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const data = {
        notification: values
      };
      await axios.put('/v1/restaurants', data);

      await dispatch(getRestaurantSettings());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateDeliverySetting(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const data = {
        delivery: {
          waiting_time: values.waiting_time,
          additional_cost: values.additional_cost,
          delivery_fee: {
            active: values.delivery_active,
            minimum_order: values.delivery_minimum_order,
            amount: values.delivery_amount
          }
        }
      };
      await axios.put('/v1/restaurants', data);

      await dispatch(getRestaurantSettings());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updatePickupSetting(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const data = {
        pickup: {
          waiting_time: values.waiting_time,
          additional_cost: values.additional_cost,
          delivery_fee: {
            active: values.delivery_active,
            minimum_order: values.delivery_minimum_order,
            amount: values.delivery_amount
          }
        }
      };
      await axios.put('/v1/restaurants', data);

      await dispatch(getRestaurantSettings());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateWorkingHoursSetting(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const data = {
        working_hours: {
          schedule: values.schedule,
          day_off: values.day_off
        }
      };
      await axios.put('/v1/restaurants', data);

      await dispatch(getRestaurantSettings());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRestaurantSettings() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: {
          status: string;
          restaurant: SettingType;
        };
      } = await axios.get(`/v1/restaurants`);
      const values = response.data.restaurant;

      dispatch(slice.actions.getSettingSuccess(values));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRestaurantMembers() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: any;
      } = await axios.get(`/v1/restaurants/members`);
      const values = response.data.members;

      dispatch(slice.actions.getMemberSuccess(values));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRoles() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: {
        data: any;
      } = await axios.get(`/v1/role`);
      const values = response.data.role;

      dispatch(slice.actions.getRoleSuccess(values));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addRoles(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.post(`/v1/role`, values);

      dispatch(getRoles());
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteRestaurantMembers(id: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.delete(`/v1/restaurants/members/${id}/remove`);

      dispatch(getRestaurantMembers());
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addRestaurantMembers(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    try {
      await axios.post(`/v1/restaurants/members/create`, values);

      dispatch(getRestaurantMembers());
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateRestaurantMembers(values: any, _id: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    try {
      await axios.put(`/v1/restaurants/members/${_id}/update`, values);

      dispatch(getRestaurantMembers());
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function toggleOpenSetting() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.post('/v1/restaurants/open', {});

      dispatch(slice.actions.toggleOpenSetting());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getAvailablePlans() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/v1/subscription/plans/trial');

      dispatch(slice.actions.getAvailablePlansSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
