import { createContext, ReactNode } from 'react';
import { Ability, AbilityBuilder } from '@casl/ability';
import { createContextualCan } from '@casl/react';

// create empty ability
const ability = new Ability();

export const AbilityContext = createContext(ability);

export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider = ({ children }: { children: ReactNode }) => (
  <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
);

export const updateAbility = (ability: Ability, permissions: string[]) => {
  const { can, rules } = new AbilityBuilder(Ability);

  permissions.forEach((permission) => {
    can('manage', permission);
  });

  ability.update(rules);
};
