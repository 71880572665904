import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
//
import { CustomerState, CustomersType } from '../../@types/customer';

// ----------------------------------------------------------------------

const initialState: CustomerState = {
  isLoading: false,
  error: false,
  isOpenAddress: false,
  isOpenCard: false,
  sortBy: null,
  customers: {
    sortBy: null,
    data: []
  }
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET  CUSTOMER
    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.customers.data = action.payload;
    },

    //  SORT & FILTER CUSTOMER
    sortByCustomer(state, action) {
      state.customers.sortBy = action.payload;
    },

    // UPDATE CUSTOMER
    updateCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customers.data.push(action.payload);
    },

    // DELETE CUSTOMER
    deleteCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customers.data = action.payload;
    },

    // OPEN MODAL
    openCard(state) {
      state.isOpenCard = true;
    },

    // CLOSE MODAL
    closeCard(state) {
      state.isOpenCard = false;
    },

    // OPEN MODAL
    openAddress(state) {
      state.isOpenAddress = true;
    },

    // CLOSE MODAL
    closeAddress(state) {
      state.isOpenAddress = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openCard,
  closeCard,
  openAddress,
  closeAddress,
  getCustomersSuccess,
  updateCustomerSuccess,
  sortByCustomer
} = slice.actions;

// ----------------------------------------------------------------------

export function getCustomers() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/v1/customers');

      dispatch(slice.actions.getCustomersSuccess(response.data.customers));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCustomerById(customerId: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        customerId.map(async (id) => {
          await axios.delete(`/v1/customers/${id}`);
        })
      );

      dispatch(getCustomers());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addCustomer(values: { name: string; image: string | null }) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: { data: { customer: CustomersType } } = await axios.post(
        '/api/customers/new',
        values
      );

      dispatch(slice.actions.updateCustomerSuccess(response.data.customer));
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateCustomer(values: CustomersType, id: string | undefined) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.put('/api/customers/update', values, { params: { id } });

      dispatch(slice.actions.updateCustomerSuccess(response));
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
