import { forwardRef, useRef, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';

import { OrdersType, OrderDetailType } from '../@types/live-orders';

import { SettingState } from '../@types/settings';
import { useSelector } from '../redux/store';

type ReceiptProps = {
  order: OrdersType;
};

const MarkSymbol = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0C3.14019 0 0 3.14019 0 7C0 10.8598 3.14019 14 7 14C10.8598 14 14 10.8598 14 7C14 3.14019 10.8598 0 7 0ZM10.279 5.46386L6.18263 9.56025C5.90012 9.84284 5.44124 9.84304 5.15856 9.56025L3.72099 8.12263C3.43819 7.83983 3.43819 7.38136 3.72099 7.09856C4.00378 6.81577 4.4623 6.81577 4.74505 7.09856L5.6706 8.02411L9.25491 4.4398C9.53766 4.15704 9.99618 4.15704 10.279 4.4398C10.5618 4.72255 10.5618 5.18106 10.279 5.46386Z"
      fill="black"
    />
  </svg>
);

const RestaurantSymbol = () => (
  <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9993 3.63254C14.999 3.61903 14.9984 3.60558 14.9973 3.59228C14.9965 3.58328 14.9955 3.57427 14.9944 3.56529C14.993 3.55386 14.9912 3.54259 14.9892 3.53141C14.9872 3.52032 14.9848 3.50926 14.9822 3.49824C14.9801 3.48929 14.9779 3.48038 14.9754 3.47153C14.9718 3.45879 14.9677 3.44612 14.9633 3.43348C14.9617 3.4291 14.9607 3.42475 14.9591 3.42037L13.8373 0.428711H13.1506H12.3912H2.60883H1.84939H1.16277L0.0409042 3.42034C0.0392892 3.42472 0.0382332 3.42907 0.0367113 3.43345C0.03227 3.44609 0.0282323 3.45879 0.0246295 3.4715C0.0221137 3.48032 0.0198775 3.48923 0.0177655 3.49821C0.0150945 3.50926 0.0127962 3.52032 0.0107774 3.53138C0.00875855 3.54256 0.00701924 3.55383 0.00559054 3.56526C0.00447243 3.57427 0.00344749 3.58328 0.00273314 3.59225C0.00161503 3.60558 0.000962799 3.61899 0.000683271 3.63251C0.000590095 3.63701 0 3.64145 0 3.64605V6.21749C0 6.57249 0.287821 6.86034 0.642852 6.86034H1.2857V9.68786V12.2593C1.2857 12.6143 1.57353 12.9022 1.92856 12.9022H8.35714H13.0714C13.4265 12.9022 13.7143 12.6143 13.7143 12.2593V6.86037H14.3571C14.7122 6.86037 15 6.57249 15 6.21752V3.64608C15 3.64148 14.9994 3.63707 14.9993 3.63254ZM2.57141 6.86037H7.71426V9.04501H2.57141V6.86037ZM7.71426 11.6164H2.57141V10.3307H7.71429V11.6164H7.71426ZM12.4286 11.6164H8.99999V9.68786V6.86034H12.4286V11.6164Z"
      fill="black"
    />
  </svg>
);

const DeliverySymbol = () => (
  <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2578 0.117798C15.9918 0.520172 16.6551 1.53412 17.4867 2.92969H14.2578V0.117798ZM4.12415 3.04688C4.46365 3.04688 4.71008 3.21075 4.71008 3.59375C4.71008 3.91678 4.4487 4.17877 4.12582 4.17969H0.585938C0.262299 4.17969 0 4.44214 0 4.76562C0 5.08926 0.262299 5.35156 0.585938 5.35156H5.85938C6.18607 5.35156 6.4476 5.61371 6.4476 5.9375C6.4476 6.26114 6.1853 6.52344 5.86166 6.52344H0.585938C0.262299 6.52344 0 6.78574 0 7.10938C0 7.43301 0.262299 7.69531 0.585938 7.69531H1.875V9.45312C1.875 9.77676 2.1373 10.0391 2.46094 10.0391H3.63876C3.84872 11.0733 4.76257 11.8359 5.83984 11.8359C6.91711 11.8359 7.83096 11.0733 8.04092 10.0391H13.9903C14.2003 11.0733 15.1141 11.8359 16.1914 11.8359C17.2687 11.8359 18.1825 11.0733 18.3925 10.0391H19.4141C19.7377 10.0391 20 9.77676 20 9.45312V5.9375C20 4.21829 18.1866 4.10416 18.185 4.10156H13.6719C13.3482 4.10156 13.0859 3.83926 13.0859 3.51562V0H2.46094C2.1373 0 1.875 0.262299 1.875 0.585938V1.875H1.17188C0.848236 1.875 0.585938 2.1373 0.585938 2.46094C0.585938 2.78458 0.848236 3.04688 1.17188 3.04688H4.12415ZM16.951 8.83026C17.3705 9.24973 17.3705 9.93011 16.951 10.3496C16.2762 11.0243 15.1172 10.5443 15.1172 9.58984C15.1172 8.63556 16.2762 8.15552 16.951 8.83026ZM6.59943 8.83026C7.01889 9.24973 7.01889 9.93011 6.59943 10.3496C5.92468 11.0243 4.76562 10.5443 4.76562 9.58984C4.76562 8.63556 5.92468 8.15552 6.59943 8.83026Z"
      fill="black"
    />
  </svg>
);

const renderIngredients = (orderItem: OrderDetailType, size: number) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      fontSize: `${size}px`,
      color: 'rgba(51, 51, 51, 0.79)'
    }}
  >
    {orderItem.ingredients.map((ingredient, index) =>
      ingredient.items.map(
        (item, index) =>
          !item.include && (
            <span
              key={index}
              style={{
                fontWeight: 600
              }}
            >
              + {item.name} {item.doubled && 'x2'}
            </span>
          )
      )
    )}
    {orderItem.ingredients.map((ingredient, index) =>
      ingredient.excluded_ingredients.map((item, index) => (
        <span
          key={index}
          style={{
            fontWeight: 600
          }}
        >
          - {item.name} {item.doubled && 'x2'}
        </span>
      ))
    )}
  </div>
);

const contentSwitch = (orderItem: OrderDetailType) => {
  switch (orderItem.type) {
    case 'split-product':
      return (
        orderItem.items && (
          <>
            {(orderItem.items[0].variables || orderItem.items[0].extras) && (
              <div
                style={{
                  fontSize: '16px',
                  fontWeight: 500
                }}
              >
                {orderItem.items[0].variables &&
                  orderItem.items[0].variables.map((variable, index) => (
                    <span key={index}>{variable.attribute.attribute.name}</span>
                  ))}
                {orderItem.items[0].variables && orderItem.items[0].extras && (
                  <span
                    style={{
                      color: '#ebebeb',
                      fontWeight: 'normal'
                    }}
                  >
                    {' '}
                    |{' '}
                  </span>
                )}
                {orderItem.items[0].extras &&
                  orderItem.items[0].extras.map((extra, index) => (
                    <span key={index}>{extra.attribute.attribute.name}</span>
                  ))}
              </div>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {orderItem.items.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'start'
                  }}
                  key={index}
                >
                  <div
                    style={{
                      height: '16px',
                      flexBasis: '16px',
                      flexGrow: '0',
                      flexShrink: '0',
                      width: '16px',
                      background: `linear-gradient(${
                        index === 0 ? '90deg' : '-90deg'
                      },#333,#333 50%,#e0e0e0 50%,#e0e0e0)`,
                      borderRadius: '50%'
                    }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px'
                    }}
                  >
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '500'
                      }}
                    >
                      {item.name}
                    </span>
                    {item.ingredients && renderIngredients(item, 12)}
                  </div>
                </div>
              ))}
            </div>
          </>
        )
      );
    case 'deals':
      return (
        orderItem.items && (
          <div>
            {orderItem.items.map((item, index) => (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}
                  key={index}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: '20px'
                      }}
                    >
                      {orderItem.quantity} x {item.name}
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '26px',
                    gap: '12px'
                  }}
                >
                  {(item.variables || item.extras) && (
                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 500
                      }}
                    >
                      {item.variables &&
                        item.variables.map((variable, index) => (
                          <span key={index}>{variable.attribute.attribute.name}</span>
                        ))}
                      {item.variables && item.extras && (
                        <span
                          style={{
                            color: '#ebebeb',
                            fontWeight: 'normal'
                          }}
                        >
                          {' '}
                          |{' '}
                        </span>
                      )}
                      {item.extras &&
                        item.extras.map((extra, index) => (
                          <span key={index}>{extra.attribute.attribute.name}</span>
                        ))}
                    </div>
                  )}
                  {item.ingredients && renderIngredients(item, 14)}
                </div>
              </>
            ))}
          </div>
        )
      );
    default:
      return orderItem.ingredients && renderIngredients(orderItem, 14);
  }
};

export const PrintableReceipt = forwardRef<HTMLDivElement, ReceiptProps>((props, ref) => {
  const { order } = props;

  const setting = useSelector((state: { setting: SettingState }) => state.setting.setting);

  return (
    <div ref={ref}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"
        rel="stylesheet"
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          color: '#333',
          fontFamily: 'Poppins, sans-serif',
          margin: 0
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            margin: '16px 20px',
            gap: '8px'
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: '20px'
            }}
          >
            {`${setting.business_name}`}
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: '14px'
            }}
          >
            {`${setting.address?.address_detail}, ${setting.address?.city}`}
            <br />
            Tel: {`${setting.phone?.phone_number}`}
          </span>
        </div>

        <div
          style={{
            border: '1px dashed #d5d5d5',
            margin: '0 20px',
            borderLeft: '0',
            borderRight: '0'
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 0',
            gap: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              padding: '3px 20px',
              color: order.date_paid ? '#333' : '#fff',
              backgroundColor: order.date_paid ? '' : '#333'
            }}
          >
            <span
              style={{
                fontWeight: 400
              }}
            >
              Status
            </span>
            <span
              style={{
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              {order.date_paid ? (
                <div
                  style={{
                    display: 'flex',
                    gap: '4px',
                    alignItems: 'center'
                  }}
                >
                  <MarkSymbol />
                  <span>PAID</span>
                </div>
              ) : (
                'UNPAID'
              )}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              margin: '0 20px',
              color: '#333'
            }}
          >
            <span
              style={{
                fontWeight: 400
              }}
            >
              Order no
            </span>
            <span
              style={{
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              {`#${order.order_number}`}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              margin: '0 20px',
              color: '#333'
            }}
          >
            <span
              style={{
                fontWeight: 400
              }}
            >
              Customer
            </span>
            <span
              style={{
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              {order.customer?.name}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              margin: '0 20px',
              color: '#333'
            }}
          >
            <span
              style={{
                fontWeight: 400
              }}
            >
              Payment
            </span>
            <span
              style={{
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              {order.payment.payment_method_title}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              margin: '0 20px',
              color: '#333'
            }}
          >
            <span
              style={{
                fontWeight: 400
              }}
            >
              Method
            </span>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              {order.order_method === 'pickup' ? <RestaurantSymbol /> : <DeliverySymbol />}
              {/* convert only the first character to uppercase */}
              {order.order_method.charAt(0).toUpperCase() + order.order_method.slice(1)}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              margin: '0 20px',
              color: '#333'
            }}
          >
            <span
              style={{
                fontWeight: 400
              }}
            >
              Phone Number
            </span>
            <div
              style={{
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              {order.customer?.phone?.phone_number}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '14px',
              margin: '0 20px',
              color: '#333'
            }}
          >
            <span
              style={{
                fontWeight: 400
              }}
            >
              Time
            </span>
            <span
              style={{
                fontWeight: 500,
                textAlign: 'right'
              }}
            >
              {format(new Date(order.createdAt), 'd.M.Y - HH:mm')}
            </span>
          </div>
        </div>

        {order.order_method !== 'pickup' && (
          <>
            <div
              style={{
                border: '1px dashed #d5d5d5',
                margin: '0 20px',
                borderLeft: '0',
                borderRight: '0'
              }}
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                gap: '8px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '16px',
                  gap: '4px'
                }}
              >
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 600,
                    color: 'rgba(51, 51, 51, 0.79)'
                  }}
                >
                  Address
                </span>
                <span>
                  {order.shipping.address}
                  <br />
                  {order.shipping.address_detail}
                </span>
              </div>
            </div>
          </>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px',
            gap: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 16,
              marginTop: '16px',
              gap: '4px'
            }}
          >
            <span
              style={{
                fontSize: '14px',
                fontWeight: 600,
                color: 'rgba(51, 51, 51, 0.79)'
              }}
            >
              {order.order_method === 'delivery' ? 'Note to Courier' : 'Note'}
            </span>
            <span>{order.order_note}</span>
          </div>
        </div>

        <div
          style={{
            border: '1px dashed #d5d5d5',
            margin: '0 20px',
            borderLeft: '0',
            borderRight: '0'
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '16px 20px',
            gap: '16px'
          }}
        >
          {order.items.map((item, index) => (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px'
              }}
              key={index}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '20px'
                  }}
                >
                  {`${item.quantity} x `}
                  {item.name}
                </span>
                <span
                  style={{
                    textAlign: 'right',
                    fontSize: '18px',
                    fontWeight: 600
                  }}
                >
                  {item.total} kr.
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '26px',
                  gap: '12px'
                }}
              >
                {(item.variables || item.extras) && (
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: 500
                    }}
                  >
                    {item.variables &&
                      item.variables.map((variable, index) => (
                        <span key={index}>{variable.attribute.attribute.name}</span>
                      ))}
                    {item.variables && item.extras && (
                      <span
                        style={{
                          color: '#ebebeb',
                          fontWeight: 'normal'
                        }}
                      >
                        {' '}
                        |{' '}
                      </span>
                    )}
                    {item.extras &&
                      item.extras.map((extra, index) => (
                        <span key={index}>{extra.attribute.attribute.name}</span>
                      ))}
                  </div>
                )}
                {contentSwitch(item)}
              </div>
            </div>
          ))}
        </div>

        {/* <div
          style={{
            padding: '16px 20px 20px 20px',
            backgroundColor: 'rgba(247, 56, 57, 0.09)'
          }}
        >
          <span
            style={{
              fontWeight: 600,
              fontSize: '14px',
              color: 'rgba(51, 51, 51, 0.79)',
              marginBottom: '12px'
            }}
          >
            Extras
          </span>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <span
              style={{
                fontWeight: 500,
                fontSize: '20px'
              }}
            >
              1 x Coca Cola
            </span>
            <span
              style={{
                textAlign: 'right',
                fontWeight: 600,
                fontSize: '18px'
              }}
            >
              390 kr.
            </span>
          </div>
        </div> */}

        <div
          style={{
            border: '1px dashed #d5d5d5',
            margin: '0 20px',
            borderLeft: '0',
            borderRight: '0'
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '16px 20px',
            gap: '12px'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                fontSize: '15px'
              }}
            >
              <span>Sub total</span>
              <span
                style={{
                  textAlign: 'right'
                }}
              >
                {order.sub_total} kr.
              </span>
            </div>

            {order.additional_cost &&
              order.additional_cost.map((cost, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '15px'
                  }}
                >
                  <span>{cost.name}</span>
                  <span
                    style={{
                      textAlign: 'right'
                    }}
                  >
                    {cost.amount} kr.
                  </span>
                </div>
              ))}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontSize: '18px'
            }}
          >
            <span style={{ fontWeight: 500 }}>Total</span>
            <span
              style={{
                textAlign: 'right',
                fontWeight: 600
              }}
            >
              {order.total} kr.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export const ReceiptPrintOnDraw = ({ order }: ReceiptProps) => {
  const printRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  useEffect(() => {
    handlePrint();
  }, [order]);

  return (
    <div
      style={{
        overflow: 'hidden',
        height: 0,
        width: 0
      }}
    >
      <PrintableReceipt ref={printRef} order={order} />
    </div>
  );
};
