import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
//
import { OrdersState, OrdersType, OrderStatusTypes } from '../../@types/live-orders';

const initialState: OrdersState = {
  isLoading: false,
  error: false,
  isOpenAddress: false,
  isOpenCard: false,
  sortBy: null,
  orders: [],
  page: 0,
  perPage: 50
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ALL ORDERS
    getAllOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // SORT & FILTER ORDERS
    sortByOrder(state, action) {
      state.sortBy = action.payload;
    },

    // GET ONE ORDER
    getOneOrderSuccess(state, action) {
      state.isLoading = false;
      state.orders = [action.payload];
    },

    // UPDATE ALL ORDERS
    updateAllOrderSuccess(state, action) {
      state.isLoading = false;
      state.orders.push(action.payload);
    },

    // UPDATE ALL ORDERS
    addMoreOrders(state, action) {
      state.isLoading = false;
      state.orders = [...state.orders, ...action.payload];
    },

    // DELETE ORDERS
    deleteOrderSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // OPEN MODAL
    openCard(state) {
      state.isOpenCard = true;
    },

    // CLOSE MODAL
    closeCard(state) {
      state.isOpenCard = false;
    },

    // OPEN MODAL
    openAddress(state) {
      state.isOpenAddress = true;
    },

    // CLOSE MODAL
    closeAddress(state) {
      state.isOpenAddress = false;
    },

    changePage(state, action) {
      console.log(action);
      state.page = action.payload;
    },

    // ADVANCE ORDER PROCESSS
    advanceOrderStatus(
      state,
      action: {
        payload: {
          orderId: string;
          origin: OrderStatusTypes;
          goTo: OrderStatusTypes;
        };
      }
    ) {
      const { orderId, origin, goTo } = action.payload;

      let order: OrdersType | undefined;

      // this switch case handles advance order status:
      // 1. from pending to processing
      // 2. from processing to waiting_deliver
      // 3. from processing to waiting_pickup
      switch (goTo) {
        case 'processing':
          // find order from pending store
          order = state.orders.find((order) => order._id === orderId);

          if (order) {
            // change order status
            order.order_status = goTo;
          }
          break;
        case 'waiting_deliver':
          // find order from processing store
          order = state.orders.find((order) => order._id === orderId);

          if (order) {
            // change order status
            order.order_status = goTo;
          }
          break;
        case 'waiting_pickup':
          // find order from processing store
          order = state.orders.find((order) => order._id === orderId);

          if (order) {
            // change order status
            order.order_status = goTo;
          }
          break;
        default:
          break;
      }

      // this switch case handles advance order status:
      // 1. from waiting_deliver to completed
      // 2. from waiting_pickup to completed
      switch (origin) {
        case 'waiting_deliver':
          // find order from waiting_deliver store
          order = state.orders.find((order) => order._id === orderId);

          if (order) {
            // change order status
            order.order_status = goTo;
          }
          break;
        case 'waiting_pickup':
          // find order from waiting_pickup store
          order = state.orders.find((order) => order._id === orderId);

          if (order) {
            // change order status
            order.order_status = goTo;
          }
          break;
        default:
          break;
      }
    },

    // SOCKET RELATED ACTIONS
    // APPEND ORDERS
    socketAppendOrder(
      state,
      action: {
        payload: OrdersType;
      }
    ) {
      // put action.payload to the front of the array
      state.orders.unshift(action.payload);
      // state.newOrders.newData.unshift(action.payload);
    },

    socketUpdateOrder(
      state,
      action: {
        payload: any;
      }
    ) {
      state.orders = state.orders.map((item) => {
        if (item._id === action.payload._id) return action.payload;

        return item;
      });
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openCard,
  closeCard,
  openAddress,
  closeAddress,
  getAllOrdersSuccess,
  updateAllOrderSuccess,
  sortByOrder,
  changePage,
  advanceOrderStatus,
  socketAppendOrder,
  socketUpdateOrder
} = slice.actions;

// ----------------------------------------------------------------------

export function getAllOrders(page?: number, perPage?: number) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    const paginationPage = page === 0 ? 1 : page;

    try {
      const endpoint = paginationPage
        ? `/v1/order?size=${perPage}&page=${paginationPage}`
        : '/v1/order';
      const response: any = await axios.get(endpoint);

      dispatch(slice.actions.getAllOrdersSuccess(response.data.orders));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrderById(id: string) {
  return async () => {
    const { dispatch } = store;

    try {
      const response: any = await axios.get(`/v1/order/${id}`);

      dispatch(slice.actions.getOneOrderSuccess(response.data.orders));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function loadMoreOrders(page: number, perPage: number) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());
    console.log(`load ${perPage} orders for page ${page}`);

    try {
      const response: any = await axios.get(`/v1/order?size=${perPage}&page=${page}`);
      console.log('orders loaded');
      dispatch(slice.actions.addMoreOrders(response.data.orders));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteOrderById(orderId: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await Promise.all(
        orderId.map(async (id) => {
          await axios.delete(`/v1/order/${id}`);
        })
      );

      dispatch(getAllOrders());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewOrders() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/v1/order?order_status=pending');

      // TO-DO: MOVE THIS FILTER TO PAGE LOGIC
      const filtered = response.data.orders.filter((item: any) => {
        if (item.payment.payment_id === 'aur' && item.date_paid === null) return false;
        return true;
      });

      // dispatch(slice.actions.getNewOrdersSuccess(filtered));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateToNextProcess(
  orderId: string,
  origin: OrderStatusTypes,
  goTo: OrderStatusTypes
) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.put(`/v1/order/${orderId}/update`, {
        order_status: goTo
      });

      dispatch(
        advanceOrderStatus({
          orderId,
          origin,
          goTo
        })
      );
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
