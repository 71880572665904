import { ReactNode, useContext } from 'react';
import { Container, Alert, AlertTitle } from '@material-ui/core';
import { AbilityContext } from '../contexts/CASLContext';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  children: ReactNode | string;
  casl_id: string;
};

export default function RoleBasedGuard({ children, casl_id }: RoleBasedGuardProp) {
  const ability = useContext(AbilityContext);

  // if user doesn't have the required permission, show an alert:
  if (ability.cannot('manage', casl_id)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    );
  }

  // otherwise, show the children:
  return <>{children}</>;
}
