import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
//
import { PaymentState, PaymentsType } from '../../@types/payments';

// ----------------------------------------------------------------------

const initialState: PaymentState = {
  isLoading: false,
  error: false,
  payments: []
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET  CUSTOMER
    getPaymentsSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    },

    // UPDATE CUSTOMER
    updatePaymentSuccess(state, action) {
      state.isLoading = false;
      state.payments.push(action.payload);
    },

    // DELETE CUSTOMER
    deletePaymentSuccess(state, action) {
      state.isLoading = false;
      state.payments = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getPaymentsSuccess, updatePaymentSuccess } = slice.actions;

// ----------------------------------------------------------------------
export function getPayments() {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get(`/v1/payment-gateways/all`);

      dispatch(slice.actions.getPaymentsSuccess(response.data.payment_gateways));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function activatePayments(name: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.get(`/v1/payment-gateways/${name}/activate`);

      dispatch(getPayments());
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function enablePayments(name: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.get(`/v1/payment-gateways/${name}/enable`);

      dispatch(getPayments());
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function disablePayments(name: string) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.get(`/v1/payment-gateways/${name}/disable`);

      dispatch(getPayments());
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePayments(id: string[]) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: { data: { payments: PaymentsType } } = await axios.delete(
        '/api/payments/customer',
        {
          params: { id }
        }
      );

      dispatch(slice.actions.getPaymentsSuccess(response.data));
    } catch (error: any) {
      console.log(error);

      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addPayment(values: { name: string; image: string | null }) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      const response: { data: { customer: PaymentsType } } = await axios.post(
        '/api/payments/new',
        values
      );

      dispatch(slice.actions.updatePaymentSuccess(response.data));
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updatePaypal(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put('/v1/payment-gateways/paypal/update', values);

      getPayments();
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateCard(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put('/v1/payment-gateways/card/update', values);

      getPayments();
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------`
export function updateAur(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put('/v1/payment-gateways/aur/update', values);

      getPayments();
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateValitor(values: any) {
  return async () => {
    const { dispatch } = store;

    dispatch(slice.actions.startLoading());

    try {
      await axios.put('/v1/payment-gateways/valitor/update', values);

      getPayments();
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
