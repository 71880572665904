// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  restaurant: getIcon('ic_restaurant'),
  orders: getIcon('ic_cart'),
  customers: getIcon('ic_customer'),
  integration: getIcon('ic_integration'),
  payment: getIcon('ic_payment'),
  settings: getIcon('ic_settings'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  booking: getIcon('ic_booking'),
  productsales: getIcon('ic_productsales')
};

const sidebarConfig = [
  // MENU
  // ----------------------------------------------------------------------
  {
    subheader: 'menu',
    items: [
      { title: 'dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.dashboard },
      {
        title: 'restaurant',
        path: PATH_DASHBOARD.restaurant.root,
        icon: ICONS.restaurant,
        children: [
          { title: 'products', path: PATH_DASHBOARD.restaurant.product },
          { title: 'categories', path: PATH_DASHBOARD.restaurant.category },
          { title: 'tags', path: PATH_DASHBOARD.restaurant.tag },
          { title: 'ingredients', path: PATH_DASHBOARD.restaurant.ingredients },
          { title: 'variables', path: PATH_DASHBOARD.restaurant.variable },
          { title: 'giftcards', path: PATH_DASHBOARD.restaurant.giftcards },
          { title: 'coupons', path: PATH_DASHBOARD.restaurant.coupons },
          { title: 'product deals', path: PATH_DASHBOARD.restaurant.deals },
          { title: 'promotions', path: PATH_DASHBOARD.restaurant.promotions }
        ]
      },
      {
        title: 'orders',
        casl_id: 'order',
        path: PATH_DASHBOARD.liveorders.root,
        icon: ICONS.orders,
        children: [
          { title: 'all orders', path: PATH_DASHBOARD.liveorders.allOrders },
          { title: 'live orders', path: PATH_DASHBOARD.liveorders.viewOnScreen }
          // { title: 'draft orders', path: PATH_DASHBOARD.liveorders.draftOrders },
          // { title: 'cart', path: PATH_DASHBOARD.liveorders.cart }
        ]
      },
      {
        title: 'clients',
        path: PATH_DASHBOARD.general.customers,
        icon: ICONS.customers,
        casl_id: 'customers'
      },
      {
        title: 'product sales',
        path: PATH_DASHBOARD.product_sales.root,
        icon: ICONS.productsales,
        children: [
          { title: 'sales by day', path: PATH_DASHBOARD.product_sales.daily },
          { title: 'sales by month', path: PATH_DASHBOARD.product_sales.monthly },
          { title: 'sales by product', path: PATH_DASHBOARD.product_sales.by_product },
          { title: 'sales by customer', path: PATH_DASHBOARD.product_sales.by_customer }
        ]
      },
      { title: 'integration', path: PATH_DASHBOARD.general.integration, icon: ICONS.integration },
      { title: 'payment', path: PATH_DASHBOARD.general.payment, icon: ICONS.payment },
      {
        title: 'settings',
        path: PATH_DASHBOARD.general.settings,
        icon: ICONS.settings,
        casl_id: 'manage_settings'
      }
    ]
  }
];

export default sidebarConfig;
