import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack5';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// auth
import { useGoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// material
import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Box,
  Button
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};
export default function LoginForm() {
  const { login, loginWithGoogle, loginWithFaceBook } = useAuth();

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const clientId = '130935973569-ne3file3s74ogus4bmpkvfm60mbtvemv.apps.googleusercontent.com';

  const onSuccessGoogle = async (res: any) => {
    console.log('Login success: ', res);
    try {
      await loginWithGoogle(res.tokenId);
      enqueueSnackbar('Login success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      // if (isMountedRef.current) {
      //   setSubmitting(false);
      // }
    } catch (error: any) {
      console.error(error);
      // if (isMountedRef.current) {
      //   setErrors({ afterSubmit: error.message });
      //   setSubmitting(false);
      // }
    }
  };

  const onSuccessFacebook = async (res: any) => {
    console.log('Login success: ', res);
    try {
      await loginWithFaceBook(res.accessToken, res.userID);
      enqueueSnackbar('Login success', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      // if (isMountedRef.current) {
      //   setSubmitting(false);
      // }
    } catch (error: any) {
      console.error(error);
      // if (isMountedRef.current) {
      //   setErrors({ afterSubmit: error.message });
      //   setSubmitting(false);
      // }
    }
  };

  const onFailure = (res: any) => {
    console.log('Login failed:', res);
    // if (isMountedRef.current) {
    //   setErrors({ afterSubmit: error.message });
    //   setSubmitting(false);
    // }
  };

  const { signIn } = useGoogleLogin({
    onSuccess: onSuccessGoogle,
    onFailure,
    clientId,
    isSignedIn: true,
    accessType: 'offline'
  });

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {errors.afterSubmit && (
            <Alert severity="error" data-cy="login-err">
              {errors.afterSubmit}
            </Alert>
          )}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 1 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Sign in
        </LoadingButton>
        <LoadingButton
          fullWidth
          type="button"
          variant="outlined"
          size="large"
          component={RouterLink}
          to={PATH_AUTH.register}
          sx={{ mt: 1 }}
        >
          Create new account
        </LoadingButton>

        <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 1 }}>
          Or login with
        </Typography>

        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
          <Box
            component="img"
            src="/static/illustrations/Google.svg"
            sx={{ height: 50, cursor: 'pointer' }}
            onClick={signIn}
          />
          <FacebookLogin
            appId="799847201418733"
            autoLoad={false}
            callback={onSuccessFacebook}
            render={(renderProps: any) => (
              <Box
                onClick={renderProps.onClick as any}
                component="img"
                src="/static/illustrations/Facebook.svg"
                sx={{ height: 50, cursor: 'pointer' }}
              />
            )}
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
}
