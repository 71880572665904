import { useEffect, useRef, createContext, ReactNode } from 'react';
import io, { Socket } from 'socket.io-client';

import { useSnackbar } from 'notistack5';
import useAuth from '../hooks/useAuth';
import { useDispatch } from '../redux/store';
import useSettings from '../hooks/useSettings';

import { socketAppendOrder, socketUpdateOrder } from '../redux/slices/live-orders';
import { addNotification } from '../redux/slices/notifications';
import { ReceiptPrintOnDraw } from '../utils/receipt';

import { OrdersType } from '../@types/live-orders';

export const SocketContext = createContext({});

interface SocketProps {
  children: ReactNode;
}

export const SocketProvider = ({ children }: SocketProps) => {
  const orders = useRef<Socket>();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { automaticPrinting } = useSettings();

  const connectSocket = () => {
    const accessToken = localStorage.getItem('accessToken');
    orders.current = io(
      `${process.env.REACT_APP_API_ENDPOINT || 'https://upsell-api.herokuapp.com'}/v1/orders`,
      {
        path: '/v1/socket',
        extraHeaders: {
          authorization: `Bearer ${accessToken}`
        }
      }
    );
  };

  const disconnectSocket = () => {
    if (orders.current) {
      orders.current.disconnect();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      connectSocket();

      if (orders.current) {
        orders.current.on('connect', () => {
          console.log('connected');
        });

        orders.current.on('new', (data: OrdersType) => {
          console.log('new order arrived:', data);
          dispatch(socketAppendOrder(data));
          enqueueSnackbar('New order arrived', {
            variant: 'info',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            },
            action: (key: any) => automaticPrinting && <ReceiptPrintOnDraw order={data} />
          });
          dispatch(
            addNotification({
              id: data._id,
              title: 'New order arrived',
              description: '',
              type: 'new-order',
              avatar: data.customer.image.url,
              createdAt: new Date(),
              isUnRead: true,
              order: data
            })
          );
        });

        orders.current.on('notification-test', (data) => {
          console.log('data', data);
          enqueueSnackbar('This is a test notification', {
            variant: 'info',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
          dispatch(
            addNotification({
              id: new Date().toISOString(),
              title: 'Test notification',
              description: 'Be not afraid',
              avatar: 'Test',
              createdAt: new Date(),
              type: 'test',
              isUnRead: true
            })
          );
        });

        orders.current.on('update-order', (data, previousStatus) => {
          dispatch(socketUpdateOrder(data));
        });

        orders.current.on('disconnect', () => {
          console.log('disconnected');
        });
      }
    }

    return () => {
      disconnectSocket();
    };
  }, [isAuthenticated]);

  return <SocketContext.Provider value={{}}>{children}</SocketContext.Provider>;
};
