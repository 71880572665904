// material
import { Box, BoxProps } from '@material-ui/core';
import Lottie from 'react-lottie';
import logo from 'lotties/spinner.json';

// ----------------------------------------------------------------------
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: logo
};

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <Lottie options={defaultOptions} />
    </Box>
  );
}
