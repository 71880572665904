import { createSlice } from '@reduxjs/toolkit';
import { store } from '../store';
// utils
import axios from '../../utils/axios';
import { MediaLibraryState } from '../../@types/media-library';

const initialState: MediaLibraryState = {
  isLoading: false,
  error: false,
  files: [],
  base64data: ''
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET ALL MEDIA FILES
    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // GET FILE BUFFER
    getFileBufferSuccess(state, action) {
      state.isLoading = false;
      state.base64data = action.payload;
    },

    // DELETE BLOB BASE64
    deleteBlobBase64(state) {
      state.base64data = '';
    },

    // DELETE ORDERS
    deleteFileSuccess(state, action) {
      state.isLoading = false;
      state.files = action.payload;
    },

    // UPLOAD MEDIA FILE
    uploadMediaFileSuccess(state, action) {
      state.isLoading = false;
      state.files.push(action.payload);
    }
  }
});

export default slice.reducer;

export const {
  getFilesSuccess,
  getFileBufferSuccess,
  deleteBlobBase64,
  uploadMediaFileSuccess,
  deleteFileSuccess
} = slice.actions;

export function getMediaFiles() {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());

    try {
      const response: any = await axios.get('/v1/media');
      dispatch(slice.actions.getFilesSuccess(response.data.files));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFileBuffer(filename: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axios.get(`/v1/media/${filename}`);
      const buff = response.data.bufferData;
      const fileData = filename.split('.');
      const fileExtension = fileData[1];
      const blob = new Blob([new Uint8Array(buff[0].data)], { type: `image/${fileExtension}` });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async () => {
        const base64data = reader.result;
        await dispatch(slice.actions.getFileBufferSuccess(base64data));
      };
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteMediaFile(fileName: string) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/v1/media/${fileName}`);
      dispatch(getMediaFiles());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadMediaFile(file: any) {
  return async () => {
    const { dispatch } = store;
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('file', file);
      await axios.post(`/v1/upload`, formData, {
        headers: {
          'Content-Type': `multipart/form-data`
        }
      });
      dispatch(getMediaFiles());
    } catch (error: any) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
