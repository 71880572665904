// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user?.display_picture}
      alt={user?.firstName}
      color={user?.display_picture ? 'default' : createAvatar(user?.firstName).color}
      {...other}
      data-cy="avatar"
    >
      {createAvatar(user?.firstName).name}
    </MAvatar>
  );
}
