import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import RestaurantReducer from './slices/restaurant';
import CustomerReducer from './slices/customer';
import PaymentReducer from './slices/payment';
import OrderReducer from './slices/live-orders';
import NotificationReducer from './slices/notifications';
import SettingReducer from './slices/setting';
import MediaLibraryReducer from './slices/media-library';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};
const TagsPersistConfig = {
  key: 'tags',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy']
};
const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const orderPersistConfig = {
  key: 'order',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy']
};

const mediaPersistConfig = {
  key: 'media',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  calendar: calendarReducer,
  payments: PaymentReducer,
  setting: SettingReducer,
  notifications: NotificationReducer,
  orders: persistReducer(orderPersistConfig, OrderReducer),
  product: persistReducer(productPersistConfig, productReducer),
  restaurant: persistReducer(TagsPersistConfig, RestaurantReducer),
  customer: persistReducer(customerPersistConfig, CustomerReducer),
  media: persistReducer(mediaPersistConfig, MediaLibraryReducer)
});

export { rootPersistConfig, rootReducer };
