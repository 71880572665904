// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Link, Container, Typography } from '@material-ui/core';

import Lottie from 'react-lottie';
import logo from 'lotties/login_logo.json';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 664,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: logo
};

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Upsell">
      <MHidden width="mdDown">
        <SectionStyle>
          <Stack sx={{ px: 5, mb: 7 }}>
            <Typography variant="h3">Serve better,</Typography>
            <Typography variant="h3">Serve more.</Typography>
            <Typography variant="caption" color="gray">
              For restaurant that love to serve their customers personal and digital
            </Typography>
          </Stack>
          <img
            style={{ height: 400 }}
            src="/static/illustrations/illustration_login_dashboard.svg"
            alt="login"
          />
          <Box
            component="img"
            src="/static/illustrations/login_pizza2.svg"
            sx={{ width: 300, height: 200, zIndex: 2, position: 'absolute', bottom: 0 }}
          />
          <Box
            component="img"
            src="/static/illustrations/login_pizza1.svg"
            sx={{
              width: 520,
              height: 320,
              zIndex: 2,
              position: 'absolute',
              top: ['30%'],
              right: ['-15%']
            }}
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="column" alignItems="center" sx={{ mb: 1 }}>
            <Lottie options={defaultOptions} height={70} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Hi, welcome back!
              </Typography>
            </Box>
          </Stack>

          <LoginForm />

          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            By signing in you are agreeing to our &nbsp;
            <Link underline="always" color="text.primary" href="#">
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link underline="always" color="text.primary" href="#">
              Privacy Policy
            </Link>
            .
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
